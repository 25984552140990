
import { computed, ref } from '@vue/composition-api';
import seedrandom from 'seedrandom';
interface Props {
  /**
   * размер аватарки в десктопе
   */
  size: '30' | '40' | '60' | '100';
  /**
   * логин пользователя
   */
  name?: string;
  /**
   * размер аватарки в мобилке
   */
  mobileSize?: '25' | '30' | '40' | '60' | '70' | '100';
  /**
   * изображение аватарки
   */
  img?: string;
}
const __sfc_main = {};
__sfc_main.props = {
  size: {
    key: "size",
    required: false,
    type: String,
    default: '60'
  },
  name: {
    key: "name",
    required: false,
    type: String,
    default: ''
  },
  mobileSize: {
    key: "mobileSize",
    required: false,
    type: String
  },
  img: {
    key: "img",
    required: false,
    type: String
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const getMobileSize = computed(() => {
    return props.mobileSize || props.size;
  });
  const firstSign = ref(props.name?.[0]);
  const seed = seedrandom(firstSign.value)();
  const color = ref(`hsl(${360 * seed}, 56%, 60%)`);
  return {
    getMobileSize,
    firstSign,
    color
  };
};
export default __sfc_main;

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import type { BonusTransaction } from '~/type/BonusTransaction'

export default function (el: any): BonusTransaction {
  return {
    orderNumber: el.ORDER_ID,
    amount: el.AMOUNT,
    name: el.EVENT_NAME?.replace(/№\S+/g, ''),
    type: el.TRANSACTION_TYPE,
    date: el.DATE_ACCRUED?.length && format(new Date(el.DATE_ACCRUED), 'dd MMMM', { locale: ru }),
  }
}

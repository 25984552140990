var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "color-avatar",
    class: [("color-avatar_" + (_vm.size)), ("color-avatar_mobile-" + (_vm.getMobileSize))],
    style: {
      backgroundColor: _vm.img ? undefined : _vm.color
    }
  }, [_vm.img ? _c('LazyImg', {
    staticClass: "color-avatar__image",
    attrs: {
      "img": _vm.img,
      "alt": _vm.name
    }
  }) : [_vm._v("\n    " + _vm._s(_vm.firstSign) + "\n  ")]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }